<template>
  <div class="applicationFormView">
    <el-dialog
      title="食物敏感检测申请单"
      :visible.sync="dialogVisible"
      width="1100px"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <div class="applicationForm">
        <el-form ref="questionnaireFrom" :model="questionnaireFrom" label-width="110px" :inline="true"
                 :disabled="false"
        >
          <el-tabs v-model="activeName">
            <el-tab-pane label="食物敏感检测申请单" name="1">
              <el-card>
                <div slot="header" class="clearfix">
                  <div class="tit">基础信息</div>
                </div>
                <el-form-item label="姓名">
                  <el-input v-model="questionnaireFrom.general.name" readonly/>
                </el-form-item>
                <el-form-item label="英文或拼音">
                  <el-input v-model="questionnaireFrom.general.eName" readonly/>
                </el-form-item>
                <el-form-item label="证件类型">
                  <el-select v-model="questionnaireFrom.general.certificateTypeCode" readonly>
                    <el-option label="身份证" value="01"></el-option>
                    <el-option label="护照" value="02"></el-option>
                    <el-option label="永久居留证件" value="03"></el-option>
                    <el-option label="回乡证" value="04"></el-option>
                    <el-option label="其他" value="05"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="证件号码">
                  <el-input v-model="questionnaireFrom.general.idCard" readonly/>
                </el-form-item>
                <el-form-item label="联系电话">
                  <el-input v-model="questionnaireFrom.general.phone" readonly/>
                </el-form-item>
                <el-form-item label="性别">
                  <el-radio-group v-model="questionnaireFrom.general.gender" readonly>
                    <el-radio label="1">男</el-radio>
                    <el-radio label="2">女</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="采样盒寄送地址">
                  <el-input v-model="questionnaireFrom.general.address" style="width: 856px" readonly/>
                </el-form-item>
              </el-card>
              <el-card style="margin-top: 20px">
                <div slot="header" class="clearfix">
                  <div class="tit">问卷调查</div>
                </div>
                <div v-for="(item,index) in problemForm" :key="index">
                  <h4>{{ item.title }}</h4>
                  <el-form-item style="padding-left: 10px" label-width="240px">
                    <el-checkbox-group v-model="questionnaireFrom.questionnaire[item.value].value">
                      <el-checkbox v-for="(value,i) in item.labelList" :key="i" :label="value.label">{{ value.name }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <div>
                    <el-form-item label-width="50px" label="其他"
                                  v-if="questionnaireFrom.questionnaire[item.value].value.includes('其他')">
                      <el-input v-model="questionnaireFrom.questionnaire[item.other].other" style="width: 800px" />
                    </el-form-item>
                  </div>
                </div>
              </el-card>
              <el-card style="margin-top: 20px">
                <div slot="header" class="clearfix">
                  <div class="tit">知情同意</div>
                </div>
                <div v-for="(item,index) in informedForm" :key="index">
                  <h4>{{ item.title }}</h4>
                  <h4>{{ questionnaireFrom.InformedConsent[item.value].value }}</h4>
                  <el-form-item style="padding-left: 10px" label-width="240px">
                    <el-radio-group v-model="questionnaireFrom.InformedConsent[item.value].value">
                      <el-radio v-for="(value,i) in item.labelList" :key="i" :label="value.label">{{ value.name }}
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
                <div class="signature">
                  <div class="subject">
                    <h4>受检者签名：</h4>
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="questionnaireFrom.InformedConsent.subject"
                      fit="contain"></el-image>
                  </div>
                  <div class="guardian">
                    <h4>监护人签名：</h4>
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="questionnaireFrom.InformedConsent.guardian"
                      fit="contain"></el-image>
                  </div>
                </div>
              </el-card>
              <el-card style="margin-top: 20px">
                <div slot="header" class="clearfix">
                  <div class="tit">注意事项</div>
                </div>
                <div class="zysx_content">
                  若有样本质量不合格的情况（血卡未干透、污染、样本量不足等）导致无法检测时，请您采集合格样本送检。
                </div>
              </el-card>
            </el-tab-pane>
            <el-tab-pane label="食物敏感症状问卷" name="2">
              <el-card>
                <div slot="header" class="clearfix">
                  <div class="tit">食物敏感症状问卷</div>
                </div>
                <div v-for="(item,index) in topicList" :key="index">
                  <h4>{{ item.title }}</h4>
                  <div v-if="index<10">
                    <el-form-item style="padding-left: 10px" label-width="240px">
                      <el-radio-group v-if="item.type === 'radio'"
                                      v-model="questionnaireFrom.symptom[index + 1].value[0]">
                        <el-radio v-for="(value,i) in item.labelList" :key="i" :label="value.label">{{ value.name }}
                        </el-radio>
                      </el-radio-group>
                      <el-checkbox-group v-if="item.type === 'checkbox'"
                                         v-model="questionnaireFrom.symptom[index + 1].value">
                        <el-checkbox v-for="(value,i) in item.labelList" :key="i" :label="value.label">{{ value.name }}
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </div>
                  <div v-else>
                    <el-form-item style="padding-left: 10px" label-width="240px">
                      <el-radio-group v-model="questionnaireFrom.symptom[index + 1].value[0]">
                        <el-radio v-for="(value,i) in item.labelList" :key="i"
                                  :label="value.label">{{ value.name }}
                        </el-radio>
                      </el-radio-group>
                      <el-checkbox-group v-if="questionnaireFrom.symptom[index + 1].value[0] === '有'"
                                         v-model="questionnaireFrom.symptom[index + 1].value">
                        <el-checkbox v-for="(value,i) in item.otherLabelList" :key="i"
                                     :label="value.label">{{ value.name }}
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label-width="50px" :label="[0,7,8,9,10].includes(index)?'描述':'其他'"
                                  v-if="questionnaireFrom.symptom[index + 1].value.includes('其他疾病')||questionnaireFrom.symptom[index + 1].value.includes('其他')|| (['是','有'].includes(questionnaireFrom.symptom[index + 1].value[0]) && [0,7,8,9].includes(index))">
                      <el-input v-model="questionnaireFrom.symptom[index + 1].other" style="width: 800px" />
                    </el-form-item>
                  </div>
                </div>
              </el-card>
            </el-tab-pane>
            <el-tab-pane label="营养调查问卷" name="3">
              <el-card>
                <div slot="header" class="clearfix">
                  <div class="tit">膳食习惯</div>
                </div>
                <div v-for="(item,index) in nutritionQuestion.dietaryHabits" :key="index">
                  <h4>{{ item.title }}</h4>
                  <el-form-item style="padding-left: 10px" label-width="240px">
                    <el-radio-group v-if="item.type === 'radio'"
                                    v-model="questionnaireFrom.nutrition.dietaryHabits[index + 1].value[0]">
                      <el-radio v-for="(value,i) in item.labelList" :key="i" :label="value.label">{{ value.name }}
                      </el-radio>
                    </el-radio-group>
                    <el-checkbox-group v-if="item.type === 'checkbox'"
                                       v-model="questionnaireFrom.nutrition.dietaryHabits[index + 1].value">
                      <el-checkbox v-for="(value,i) in item.labelList" :key="i" :label="value.label">{{ value.name }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <div>
                    <el-form-item label-width="50px" label="其他"
                                  v-if="questionnaireFrom.nutrition.dietaryHabits[index + 1].value.includes('其他')">
                      <el-input v-model="questionnaireFrom.nutrition.dietaryHabits[index + 1].other"
                                style="width: 800px" />
                    </el-form-item>
                  </div>
                </div>
              </el-card>
              <el-card style="margin-top: 20px">
                <div slot="header" class="clearfix">
                  <div class="tit">主食摄入情况</div>
                </div>
                <div v-for="(item,index) in nutritionQuestion.intakeOfStapleFoods" :key="index">
                  <h4>{{ item.title }}</h4>
                  <el-form-item style="padding-left: 10px" label-width="240px">
                    <el-radio-group v-if="item.type === 'radio'"
                                    v-model="questionnaireFrom.nutrition.intakeOfStapleFoods[index + 1].value[0]">
                      <el-radio v-for="(value,i) in item.labelList" :key="i" :label="value.label">{{ value.name }}
                      </el-radio>
                    </el-radio-group>
                    <el-checkbox-group v-if="item.type === 'checkbox'"
                                       v-model="questionnaireFrom.nutrition.intakeOfStapleFoods[index + 1].value">
                      <el-checkbox v-for="(value,i) in item.labelList" :key="i" :label="value.label">{{ value.name }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <div>
                    <el-form-item label-width="50px" label="其他"
                                  v-if="questionnaireFrom.nutrition.intakeOfStapleFoods[index + 1].value.includes('其他')">
                      <el-input v-model="questionnaireFrom.nutrition.intakeOfStapleFoods[index + 1].other"
                                style="width: 800px" />
                    </el-form-item>
                  </div>
                </div>
              </el-card>
              <el-card style="margin-top: 20px">
                <div slot="header" class="clearfix">
                  <div class="tit">就餐情况</div>
                </div>
                <div v-for="(item,index) in nutritionQuestion.diningSituation" :key="index">
                  <h4>{{ item.title }}</h4>
                  <el-form-item style="padding-left: 10px" label-width="240px">
                    <el-radio-group v-if="item.type === 'radio'"
                                    v-model="questionnaireFrom.nutrition.diningSituation[index + 1].value[0]">
                      <el-radio v-for="(value,i) in item.labelList" :key="i" :label="value.label">{{ value.name }}
                      </el-radio>
                    </el-radio-group>
                    <el-checkbox-group v-if="item.type === 'checkbox'"
                                       v-model="questionnaireFrom.nutrition.diningSituation[index + 1].value">
                      <el-checkbox v-for="(value,i) in item.labelList" :key="i" :label="value.label">{{ value.name }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <div>
                    <el-form-item label-width="50px" label="其他"
                                  v-if="questionnaireFrom.nutrition.diningSituation[index + 1].value.includes('其他')">
                      <el-input v-model="questionnaireFrom.nutrition.diningSituation[index + 1].other"
                                style="width: 800px" />
                    </el-form-item>
                  </div>
                </div>
              </el-card>
              <el-card style="margin-top: 20px">
                <div slot="header" class="clearfix">
                  <div class="tit">膳食计划</div>
                </div>
                <div>
                  <h4>{{ nutritionQuestion.mealPlan[0].title }}</h4>
                  <el-form-item style="padding-left: 10px" label-width="240px">
                    <el-radio-group v-model="questionnaireFrom.nutrition.mealPlan['1'].value[0]">
                      <el-radio v-for="(value,i) in nutritionQuestion.mealPlan[0].labelList" :key="i"
                                :label="value.label">{{ value.name }}
                      </el-radio>
                    </el-radio-group>
                    <el-checkbox-group v-if="questionnaireFrom.nutrition.mealPlan['1'].value[0] === '是'"
                                       v-model="questionnaireFrom.nutrition.mealPlan['1'].value">
                      <el-checkbox v-for="(value,i) in nutritionQuestion.mealPlan[0].otherLabelList" :key="i"
                                   :label="value.label">{{ value.name }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <div>
                    <el-form-item label-width="50px" label="其他"
                                  v-if="questionnaireFrom.nutrition.mealPlan['1'].value.includes('其他')">
                      <el-input v-model="questionnaireFrom.nutrition.mealPlan['1'].other" style="width: 800px" />
                    </el-form-item>
                  </div>
                </div>
              </el-card>
              <el-card style="margin-top: 20px">
                <div slot="header" class="clearfix">
                  <div class="tit">饮品习惯</div>
                </div>
                <div v-for="(item,index) in nutritionQuestion.beverageHabits" :key="index">
                  <h4>{{ item.title }}</h4>
                  <el-form-item style="padding-left: 10px" label-width="240px">
                    <el-radio-group v-if="item.type === 'radio'"
                                    v-model="questionnaireFrom.nutrition.beverageHabits[index + 1].value[0]">
                      <el-radio v-for="(value,i) in item.labelList" :key="i" :label="value.label">{{ value.name }}
                      </el-radio>
                    </el-radio-group>
                    <el-checkbox-group v-if="item.type === 'checkbox'"
                                       v-model="questionnaireFrom.nutrition.beverageHabits[index + 1].value">
                      <el-checkbox v-for="(value,i) in item.labelList" :key="i" :label="value.label">{{ value.name }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <div>
                    <el-form-item label-width="50px" label="其他"
                                  v-if="questionnaireFrom.nutrition.beverageHabits[index + 1].value.includes('其他')">
                      <el-input v-model="questionnaireFrom.nutrition.beverageHabits[index + 1].other"
                                style="width: 800px" />
                    </el-form-item>
                  </div>
                </div>
              </el-card>
            </el-tab-pane>
          </el-tabs>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';

export default {
  name: "applicationFormView",
  data() {
    // 这里存放数据
    return {
      dialogVisible: false,
      activeName:'1',
      questionnaireFrom: {
        general: {
          name: "",
          eName: "",
          certificateTypeCode: "",
          idCard: "",
          phone: "",
          gender: "",
          address: ""
        },
        questionnaire: {
          purpose: {
            value: [],
            other: ""
          },
          informationSources: {
            value: [],
            other: ""
          }
        },
        InformedConsent: {
          answer: {
            value: ""
          },
          cycle: {
            value: ""
          },
          handle: {
            value: ""
          },
          bear: {
            value: ""
          },
          subject: "",
          guardian: ""
        },
        symptom: {
          "1": {
            value: [],
            other: ""
          },
          "2": {
            value: [],
            other: ""
          },
          "3": {
            value: [],
            other: ""
          },
          "4": {
            value: [],
            other: ""
          },
          "5": {
            value: [],
            other: ""
          },
          "6": {
            value: [],
            other: ""
          },
          "7": {
            value: [],
            other: ""
          },
          "8": {
            value: [],
            other: ""
          },
          "9": {
            value: [],
            other: ""
          },
          "10": {
            value: [],
            other: ""
          },
          "11": {
            value: [],
            other: ""
          }
        },
        "nutrition": {
          "dietaryHabits": {
            "1": {
              "value": []
            },
            "2": {
              "value": []
            },
            "3": {
              "value": []
            },
            "4": {
              "value": []
            },
            "5": {
              "value": []
            },
            "6": {
              "value": []
            },
            "7": {
              "value": []
            },
            "8": {
              "value": []
            },
            "9": {
              "value": []
            },
            "10": {
              "value": []
            },
            "11": {
              "value": []
            },
            "12": {
              "value": []
            }
          },
          "intakeOfStapleFoods": {
            "1": {
              "value": []
            },
            "2": {
              "value": []
            },
            "3": {
              "value": []
            },
            "4": {
              "value": []
            },
            "5": {
              "value": []
            },
            "6": {
              "value": []
            },
            "7": {
              "value": []
            },
            "8": {
              "value": []
            },
            "9": {
              "value": []
            },
            "10": {
              "value": []
            },
            "11": {
              "value": []
            }
          },
          "diningSituation": {
            "1": {
              "value": []
            },
            "2": {
              "value": []
            },
            "3": {
              "value": []
            },
            "4": {
              "value": []
            }
          },
          "mealPlan": {
            "1": {
              "value": "",
              "other": ""
            }
          },
          "beverageHabits": {
            "1": {
              "value": []
            },
            "2": {
              "value": []
            },
            "3": {
              "value": []
            },
            "4": {
              "value": []
            },
            "5": {
              "value": []
            },
            "6": {
              "value": []
            },
            "7": {
              "value": []
            }
          }
        }
      },
      problemForm: [
        {
          title: "1. 进行此项检测的目的：", value: "purpose", other: "purpose", labelList: [
            { label: "体检", name: "体检" },
            { label: "希望了解一下", name: "希望了解一下" },
            { label: "有希望解决的健康问题", name: "有希望解决的健康问题" },
            { label: "指导日常膳食", name: "指导日常膳食" },
            { label: "其他", name: "其他", value: "" }
          ]
        },
        {
          title: "2.请简述获知此项检测的信息来源：",
          value: "informationSources",
          other: "informationSources",
          labelList: [
            { label: "医生建议", name: "医生建议" },
            { label: "亲友推荐", name: "亲友推荐" },
            { label: "线上宣传", name: "线上宣传" },
            { label: "科普宣传", name: "科普宣传" },
            { label: "其他", name: "其他", value: "" }
          ]
        }
      ],
      informedForm: [
        {
          title: "1. 本人已知晓本检测的目的、意义、以及检测存在的局限性和潜在风险性，对检测项目的疑问已得到解答：",
          value: "answer",
          labelList: [
            { label: "是", name: "是" },
            { label: "否", name: "否" }
          ]
        },
        {
          title: "2. 本人已知晓本检测从样本采集到报告出具的周期：", value: "cycle", labelList: [
            { label: "是", name: "是" },
            { label: "否", name: "否" }
          ]
        },
        {
          title: "3. 本人授权检测方对本检测涉及的样本医疗废弃物等进行处理：", value: "handle", labelList: [
            { label: "是", name: "是" },
            { label: "否", name: "否" }
          ]
        },
        {
          title: "4. 本人承诺以上填写的信息真实可靠，如果信息有误或缺失，本人愿意承担所带来的不利后果：",
          value: "bear",
          labelList: [
            { label: "是", name: "是" },
            { label: "否", name: "否" }
          ]
        }
      ],
      topicList: [
        {
          title: "1. 您是否有不适症状：", value: [], type: "radio", labelList: [
            { label: "无", name: "无" },
            { label: "有", name: "有" }
          ]
        },
        {
          title: "2.消化道症状：", value: [], other: "", type: "checkbox", labelList: [
            { label: "腹胀", name: "腹胀" },
            { label: "肠胃不适", name: "肠胃不适" },
            { label: "消化不良", name: "消化不良" },
            { label: "嗜糖", name: "嗜糖" },
            { label: "腹痛", name: "腹痛" },
            { label: "便秘", name: "便秘" },
            { label: "腹泻", name: "腹泻" },
            { label: "其他", name: "其他", value: "" }
          ]
        },
        {
          title: "3.皮肤及指甲症状：", value: [], other: "", type: "checkbox", labelList: [
            { label: "过敏", name: "过敏" },
            { label: "瘙痒", name: "瘙痒" },
            { label: "皮疹", name: "皮疹" },
            { label: "淤青", name: "淤青" },
            { label: "皮肤干燥", name: "皮肤干燥" },
            { label: "指甲易脆断", name: "指甲易脆断" },
            { label: "其他", name: "其他", value: "" }
          ]
        },
        {
          title: "4. 耳鼻喉、眼部及口腔症状：", value: [], other: "", type: "checkbox", labelList: [
            { label: "鼻塞", name: "鼻塞" },
            { label: "流涕", name: "流涕" },
            { label: "眼部不适/流泪", name: "眼部不适/流泪" },
            { label: "鼻痒", name: "鼻痒" },
            { label: "打喷嚏", name: "打喷嚏" },
            { label: "口腔干燥", name: "口腔干燥" },
            { label: "口臭", name: "口臭" },
            { label: "其他", name: "其他", value: "" }
          ]
        },
        {
          title: "5. 神经精神系统症状：", value: [], other: "", type: "checkbox", labelList: [
            { label: "失眠", name: "失眠" },
            { label: "焦虑/情绪不稳定/抑郁", name: "焦虑/情绪不稳定/抑郁" },
            { label: "记忆力减退", name: "记忆力减退" },
            { label: "疲劳/乏力", name: "疲劳/乏力" },
            { label: "注意力不集中", name: "注意力不集中" },
            { label: "轻度眩晕", name: "轻度眩晕" },
            { label: "嗜睡", name: "嗜睡" },
            { label: "头疼", name: "头疼" },
            { label: "其他", name: "其他", value: "" }
          ]
        },
        {
          title: "6. 骨骼肌肉系统症状：", value: [], other: "", type: "checkbox", labelList: [
            { label: "肌肉酸痛", name: "肌肉酸痛" },
            { label: "关节疼痛", name: "关节疼痛" },
            { label: "关节僵硬", name: "关节僵硬" },
            { label: "关节肿胀", name: "关节肿胀" },
            { label: "手脚发麻", name: "手脚发麻" },
            { label: "其他", name: "其他", value: "" }
          ]
        },
        {
          title: "7. 其他：", value: [], other: "", type: "checkbox", labelList: [
            { label: "甲状腺问题", name: "甲状腺问题" },
            { label: "心律不齐", name: "心律不齐" },
            { label: "性欲减退/性功能低下", name: "性欲减退/性功能低下" },
            { label: "减重困难", name: "减重困难" },
            { label: "怕冷", name: "怕冷" },
            { label: "其他", name: "其他", value: "" }
          ]
        },
        {
          title: "8. 是否曾做过食物不耐受检测：",
          type: "radio",
          value: [],
          other: "",
          left: "结果为: 对",
          sufix: "食物敏感",
          labelList: [
            { label: "否", name: "否" },
            { label: "是", name: "是", value: "其他" }
          ]
        },
        {
          title: "9. 是否曾做过过敏原检测：",
          value: [],
          type: "radio",
          other: "",
          left: "结果为: 对",
          sufix: "过敏",
          labelList: [
            { label: "否", name: "否" },
            { label: "是", name: "是", value: "其他" }
          ]
        },
        {
          title: "10. 过敏史：", value: [], other: "", type: "radio", labelList: [
            { label: "无", name: "无" },
            { label: "有", name: "有", value: "其他" }
          ]
        },
        {
          title: "11. 已诊断疾病/已存在的健康问题：", value: [], other: "", type: "checkbox", otherLabelList: [
            { label: "高血压", name: "高血压" },
            { label: "餐后高血糖", name: "餐后高血糖" },
            { label: "空腹胰岛素水平升高", name: "空腹胰岛素水平升高" },
            { label: "糖尿病", name: "糖尿病" },
            { label: "高血脂", name: "高血脂" },
            { label: "其他疾病", name: "其他疾病", value: "其他" }
          ], labelList: [
            { label: "无", name: "无" },
            { label: "有", name: "有", value: "其他" }
          ]
        }
      ],
      nutritionQuestion: {
        // 膳食习惯
        dietaryHabits: [
          {
            title: "1. 规律吃早餐？", value: [], type: "radio", labelTitle: "膳食习惯", labelList: [
              { label: "否", name: "否" },
              { label: "是", name: "是", value: "其他" }
            ]
          },
          {
            title: "2.通常在哪里吃？",
            value: [],
            other: "",
            showFlag: "1",
            labelTitle: "膳食习惯",
            type: "checkbox",
            labelList: [
              { label: "家里", name: "家里" },
              { label: "单位", name: "单位" },
              { label: "路边小摊", name: "路边小摊" }
            ]
          },
          {
            title: "3.您早餐通常吃什么？",
            value: [],
            other: "",
            showFlag: "1",
            labelTitle: "膳食习惯",
            type: "checkbox",
            labelList: [
              { label: "粥", name: "粥" },
              { label: "馒头", name: "馒头" },
              { label: "奶制品", name: "奶制品" },
              { label: "鸡蛋", name: "鸡蛋" },
              { label: "饼干", name: "饼干" },
              { label: "面包", name: "面包" },
              { label: "方便面", name: "方便面" },
              { label: "速食品", name: "速食品" },
              { label: "油炸类", name: "油炸类" }
            ]
          },
          {
            title: "4. 是否规律吃午餐？", value: [], other: "", labelTitle: "膳食习惯", type: "radio", labelList: [
              { label: "否", name: "否" },
              { label: "是", name: "是", value: "其他" }
            ]
          },
          {
            title: "5. 地点主要是？",
            value: [],
            other: "",
            showFlag: "4",
            labelTitle: "膳食习惯",
            type: "checkbox",
            labelList: [
              { label: "家里", name: "家里" },
              { label: "单位食堂", name: "单位食堂" },
              { label: "餐厅", name: "餐厅" }
            ]
          },
          {
            title: "6. 吃的主要内容:",
            value: [],
            other: "",
            showFlag: "4",
            labelTitle: "膳食习惯",
            type: "checkbox",
            labelList: [
              { label: "米饭", name: "米饭" },
              { label: "面食", name: "面食" },
              { label: "炒菜", name: "炒菜" }
            ]
          },
          {
            title: "7. 是否规律吃晚餐？", value: [], other: "", labelTitle: "膳食习惯", type: "radio", labelList: [
              { label: "否", name: "否" },
              { label: "是", name: "是", value: "其他" }
            ]
          },
          {
            title: "8. 在哪里吃?",
            value: [],
            other: "",
            showFlag: "7",
            labelTitle: "膳食习惯",
            type: "checkbox",
            labelList: [
              { label: "家里", name: "家里" },
              { label: "单位食堂", name: "单位食堂" },
              { label: "餐厅", name: "餐厅" }
            ]
          },
          {
            title: "9. 吃的主要内容:",
            value: [],
            other: "",
            showFlag: "7",
            labelTitle: "膳食习惯",
            type: "checkbox",
            labelList: [
              { label: "米饭", name: "米饭" },
              { label: "面食", name: "面食" },
              { label: "炒菜", name: "炒菜" }
            ]
          },
          {
            title: "10. 您有吃夜宵的习惯吗？", value: [], type: "radio", labelTitle: "膳食习惯", other: "", labelList: [
              { label: "基本不吃", name: "基本不吃" },
              { label: "有时吃", name: "有时吃" },
              { label: "天天吃", name: "天天吃" }
            ]
          },
          {
            title: "11. 您喜欢吃哪些零食？",
            value: [],
            other: "",
            otherLabelType: "field",
            labelTitle: "膳食习惯",
            type: "checkbox",
            labelList: [
              { label: "坚果类", name: "坚果类" },
              { label: "饼干", name: "饼干" },
              { label: "点心类", name: "点心类" },
              { label: "肉干", name: "肉干" },
              { label: "鱼干", name: "鱼干" },
              { label: "果脯类", name: "果脯类" },
              { label: "膨化食品类", name: "膨化食品类" },
              { label: "不吃零食", name: "不吃零食" },
              { label: "其他", name: "其他", value: "" }
            ]
          },
          {
            title: "12. 您每天在什么时间会想要吃零食？",
            value: [],
            labelTitle: "膳食习惯",
            other: "",
            type: "radio",
            labelList: [
              { label: "在两餐之间", name: "在两餐之间" },
              { label: "不限时", name: "不限时" },
              { label: "就餐时", name: "就餐时" },
              { label: "睡前吃", name: "睡前吃" },
              { label: "不吃零食", name: "不吃零食" }
            ]
          }],
        // 主食摄入情况
        intakeOfStapleFoods: [
          {
            title: "1. 您的主食结构是？", value: [], labelTitle: "主食摄入情况", other: "", type: "radio", labelList: [
              { label: "大米白面为主、少量粗粮薯类", name: '大米白面为主、少量粗粮薯类' },
              { label: "大米白面、粗粮、薯类三者基本等量", name: '大米白面、粗粮、薯类三者基本等量' },
              { label: "粗粮、薯类为主、少量大米白面", name: '粗粮、薯类为主、少量大米白面' },
              { label: "只吃大米白面、基本不吃粗粮、薯类", name: '只吃大米白面、基本不吃粗粮、薯类' },
            ]
          }, {
            title: "2. 您平均每天主食能吃多少？",
            value: [],
            labelTitle: "主食摄入情况",
            other: "",
            type: "radio",
            labelList: [
              { label: "5-8两", name: "5-8两" },
              { label: "8-10两", name: "8-10两" },
              { label: "2-4两", name: "2-4两" },
              { label: "2两以下", name: "2两以下" }
            ]

          }, {
            title: "3. 常吃的杂粮谷物有：",
            value: [],
            other: "",
            otherLabelType: "field",
            labelTitle: "主食摄入情况",
            type: "checkbox",
            labelList: [
              { label: "大米", name: "大米" },
              { label: "小米", name: "小米" },
              { label: "玉米", name: "玉米" },
              { label: "小麦", name: "小麦" },
              { label: "大麦", name: "大麦" },
              { label: "黑米", name: "黑米" },
              { label: "燕麦片", name: "燕麦片" },
              { label: "薏米", name: "薏米" },
              { label: "荞麦面条", name: "荞麦面条", value: "" },
              { label: "鲜玉米", name: "鲜玉米", value: "" },
              { label: "红薯", name: "红薯", value: "" },
              { label: "土豆", name: "土豆", value: "" },
              { label: "山药", name: "山药", value: "" },
              { label: "赤小豆", name: "赤小豆", value: "" },
              { label: "绿豆", name: "绿豆", value: "" },
              { label: "红豆", name: "红豆", value: "" },
              { label: "其他", name: "其他", value: "其他" }
            ]
          }, {
            title: "4. 每天奶制品及豆制品的摄入量:",
            value: [],
            other: "",
            labelTitle: "奶制品摄入情况",
            type: "checkbox",
            labelList: [
              { label: "酸奶", name: "酸奶" },
              { label: "脱脂/低脂牛奶", name: "脱脂/低脂牛奶" },
              { label: "全脂牛奶", name: "全脂牛奶" },
              { label: "0乳糖奶", name: "0乳糖奶" },
              { label: "奶酪", name: "奶酪" }
            ]
          }, {
            title: "5. 豆制品的摄入量频率和量:",
            value: [],
            other: "",
            labelTitle: "豆制品摄入情况",
            type: "checkbox",
            labelList: [
              { label: "豆浆", name: "豆浆" },
              { label: "豆腐", name: "豆腐" },
              { label: "腐竹", name: "腐竹" },
              { label: "豆腐皮", name: "豆腐皮" },
              { label: "豆腐干", name: "豆腐干" }
            ]
          }, {
            title: "6. 动物蛋白质摄入情况：:",
            value: [],
            other: "",
            otherLabelType: "field",
            labelTitle: "动物蛋白质摄入情况",
            type: "checkbox",
            labelList: [
              { label: "鸡蛋", name: "鸡蛋" },
              { label: "鹌鹑蛋", name: "鹌鹑蛋" },
              { label: "鸭蛋", name: "鸭蛋" },
              { label: "牛肉", name: "牛肉" },
              { label: "兔肉", name: "兔肉" },
              { label: "去皮鸡胸肉", name: "去皮鸡胸肉" },
              { label: "猪瘦肉", name: "猪瘦肉" },
              { label: "排骨", name: "排骨" },
              { label: "鸭血", name: "鸭血" },
              { label: "猪血", name: "猪血" },
              { label: "猪肝", name: "猪肝" },
              { label: "鱼肉", name: "鱼肉" },
              { label: "虾贝类", name: "虾贝类" },
              { label: "其他", name: "其他" }
            ]
          }, {
            title: "7. 每餐蛋白质的摄入量大概是多少？:",
            value: [],
            other: "",
            labelTitle: "动物蛋白质摄入情况",
            type: "radio",
            labelList: [
              { label: "4两以上", name: "4两以上" },
              { label: "2-4两", name: "2-4两" },
              { label: "2两以下", name: "2两以下" }
            ]
          }, {
            title: "8. 每天蔬菜摄入量大概是多少？:",
            value: [],
            other: "",
            labelTitle: "动物蛋白质摄入情况",
            type: "radio",
            labelList: [
              { label: "一斤以上", name: "一斤以上" },
              { label: "一斤以下", name: "一斤以下" }
            ]
          }, {
            title: "9. 是否每日有水果？:",
            value: [],
            other: "",
            labelTitle: "动物蛋白质摄入情况",
            type: "radio",
            labelList: [
              { label: "否", name: "否" },
              { label: "是", name: "是" }
            ]
          }, {
            title: "10. 每次吃水果的量？:",
            value: [],
            other: "",
            labelTitle: "动物蛋白质摄入情况",
            type: "radio",
            labelList: [
              { label: "半斤以上", name: "半斤以上" },
              { label: "半斤以下", name: "半斤以下" }
            ]
          }, {
            title: "11. 口味特点:",
            value: [],
            other: "",
            otherLabelType: "field",
            labelTitle: "动物蛋白质摄入情况",
            type: "checkbox",
            labelList: [
              { label: "偏甜", name: "偏甜" },
              { label: "偏辣", name: "偏辣" },
              { label: "油腻", name: "油腻" },
              { label: "清淡", name: "清淡" },
              { label: "偏咸", name: "偏咸" },
              { label: "其他", name: "其他" }
            ]
          }
        ],
        // 就餐情况
        diningSituation: [
          {
            title: "1. 进食特点：", value: [], other: "", labelTitle: "就餐情况", type: "radio", labelList: [
              { label: "狼吞虎咽型", name: "狼吞虎咽型" },
              { label: "细嚼慢咽型", name: "细嚼慢咽型" },
              { label: "吃饭速度适中", name: "吃饭速度适中" }
            ]
          }, {
            title: "2. 餐后即刻会出现以下症状：:",
            value: [],
            other: "",
            otherLabelType: "field",
            labelTitle: "就餐情况",
            type: "checkbox",
            labelList: [
              { label: "打嗝", name: "打嗝" },
              { label: "恶心", name: "恶心" },
              { label: "腹胀", name: "腹胀" },
              { label: "打喷嚏", name: "打喷嚏" },
              { label: "气短", name: "气短" },
              { label: "其他", name: "其他" }
            ]
          }, {
            title: "3. 不按时进食后会出现的症状:",
            value: [],
            other: "",
            labelTitle: "就餐情况",
            type: "radio",
            labelList: [
              { label: "无", name: "无" },
              { label: "胃部不适、疼痛", name: "胃部不适、疼痛" },
              { label: "饥饿感重", name: "饥饿感重" },
              { label: "没有饥饿感", name: "没有饥饿感" }
            ]
          }, {
            title: "4. 近段时间进食是否有规律：:",
            value: [],
            other: "",
            labelTitle: "就餐情况",
            type: "radio",
            labelList: [
              { label: "进食规律、按时进餐", name: "进食规律、按时进餐" },
              { label: "熬夜、有夜宵", name: "熬夜、有夜宵" },
              { label: "暴饮暴食", name: "暴饮暴食" },
              { label: "神经性厌食", name: "神经性厌食" },
              { label: "有意识节食", name: "有意识节食" },
              { label: "无法正常按时进食", name: "无法正常按时进食" }
            ]
          }
        ],
        // 膳食计划
        mealPlan: [
          {
            title: "1. 目前遵循一个特殊的膳食或营养计划：:",
            value: [],
            other: "",
            otherLabelType: "field",
            otherLabelList: [
              { label: "弹性素食", name: "弹性素食" },
              { label: "严格素食", name: "严格素食" },
              { label: "糖尿病饮食", name: "糖尿病饮食" },
              { label: "低脂饮食", name: "低脂饮食" },
              { label: "乳制品限制", name: "乳制品限制" },
              { label: "麸质限制饮食", name: "麸质限制饮食" },
              { label: "慢性食物过敏排除饮食", name: "慢性食物过敏排除饮食" },
              { label: "地中海饮食", name: "地中海饮食" },
              { label: "得舒饮食", name: "得舒饮食" },
              { label: "新能量饮食", name: "新能量饮食" },
              { label: "其他", name: "其他", value: "其他" }
            ],
            labelTitle: "膳食计划",
            type: "checkbox",
            labelList: [
              { label: "否", name: "否" },
              { label: "是", name: "是", value: "其他" }
            ]
          }
        ],
        // 饮品习惯
        beverageHabits: [
          {
            title: "1. 饮酒:", value: [], other: "", labelTitle: "饮品习惯", type: "radio", labelList: [
              { label: "从不饮酒", name: "从不饮酒" },
              { label: "偶尔饮酒", name: "偶尔饮酒" },
              { label: "一周1-2次", name: "一周1-2次" },
              { label: "一周多于3次", name: "一周多于3次" }
            ]
          }, {
            title: "2. 多数时候会喝:", value: [], other: "", labelTitle: "饮品习惯", type: "checkbox", labelList: [
              { label: "白酒", name: "白酒" },
              { label: "啤酒", name: "啤酒" },
              { label: "红酒", name: "红酒" }
            ]
          }, {
            title: "3. 每日饮水量:", value: [], other: "", labelTitle: "饮品习惯", type: "radio", labelList: [
              { label: "<1200ml", name: "<1200ml" },
              { label: "1200-2000ml", name: "1200-2000ml" },
              { label: ">2000ml", name: ">2000ml" }
            ]
          }, {
            title: "4. 牛奶:", value: [], other: "", labelTitle: "饮品习惯", type: "radio", labelList: [
              { label: "不喝", name: "不喝" },
              { label: "偶尔喝", name: "偶尔喝" },
              { label: "经常喝", name: "经常喝" },
              { label: "每日喝", name: "每日喝" }
            ]
          }, {
            title: "5. 茶:", value: [], other: "", labelTitle: "饮品习惯", type: "radio", labelList: [
              { label: "不喝茶", name: "不喝茶" },
              { label: "很少喝茶", name: "很少喝茶" },
              { label: "经常喝淡茶", name: "经常喝淡茶" },
              { label: "经常喝浓茶", name: "经常喝浓茶" }
            ]
          }, {
            title: "6. 饮料:", value: [], other: "", labelTitle: "饮品习惯", type: "radio", labelList: [
              { label: "基本不喝", name: "基本不喝" },
              { label: "偶尔喝", name: "偶尔喝" },
              { label: "经常喝", name: "经常喝" }
            ]
          }, {
            title: "7. 咖啡:", value: [], other: "", labelTitle: "饮品习惯", type: "radio", labelList: [
              { label: "基本不喝", name: "基本不喝" },
              { label: "偶尔喝", name: "偶尔喝" },
              { label: "经常喝", name: "经常喝" }
            ]
          }
        ]
      }
    };
  },
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  // 方法集合
  methods: {
    handleClose() {
      this.activeName = "1";
    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() {
  },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载之前
  beforeMount() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
  },
  // 生命周期 - 更新之前
  beforeUpdate() {
  },
  // 生命周期 - 更新之后
  updated() {
  },
  // 生命周期 - 销毁之前
  beforeDestroy() {
  },
  // 生命周期 - 销毁完成
  destroyed() {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated() {
  },
  // 离开的时候触发
  deactivated() {
  }
};
</script>

<style lang="scss" scoped>
.tit {
  font-size: 16px;
  font-weight: bold;
  color: #5591FF;
  margin: 0 16px;
  padding-left: 10px;
  border-left: 4px #5591FF solid;
}

.applicationFormView {
  .applicationForm {
    max-height: 700px;
    overflow-y: auto;

    .signature {
      .subject, .guardian {
        display: flex;
        align-items: center;
        margin: 10px 0;
      }
    }

    .zysx_content {
      font-size: 14px;
      font-weight: bold;
      color: #3D4961;
      padding: 10px 0 40px 0;
      margin: 0 16px;
    }

  }
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
  background: #bdbbbb;
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
  border-radius: 10px;
  background-color: #FFFFFF;
}
</style>
